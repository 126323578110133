import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo />
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <p className="lead">Comprehensive chronological history of Tata Shotton Works, while in the ownership of John Summers and Sons Limited (1896-1967), British Steel Corporation (1967-1988), British Steel PLC (1988-1999), Corus PLC (1999-2007) and Tata Steel Group since 2007.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <p>Shotton Works today (2019) is part of the Tata Steel Colors business within Tata Steel Group, one of the world’s largest steel producers with some 81,000 employees across five continents. It is the company’s premier location in Europe for the production of a unique range of metallic and paint coated products for the construction and domestic appliance sectors in the UK and overseas. There are currently some 500 Colors employees on the site, with other company activities such as Panels and Profiles and Colorsteels and contractors employing a further 300.  </p>
          <p>For three generations of people living on Deeside and in the surrounding areas of North East Flintshire, West Cheshire and the Wirral, the name of John Summers became synonymous with regular employment and comparative prosperity. To thousands of steel users across the world, it meant sheet steel products of the highest quality.</p>
          <p>For many years after 1967, when John Summers and Sons’ Shotton Works became a part of the nationalised British Steel Corporation, the name lived on. Men and women continued to work at “John Summers” and customers still talked of getting their steel from Summers.</p>
          <p>It is, perhaps, surprising therefore that the man who gave his name to what was to become one of the great steel companies of the world never saw the site on the banks of the River Dee which was transformed from barren marshland to a fully integrated iron and steelworks, covering 1,000 acres and employing at its peak 13,200 people. </p>
          <p>John Summers was born in 1822 and by the age of 20, was in business making clogs for cotton workers in the Dukinfield and Stalybridge areas of North Cheshire. At the 1851 Great Exhibition in London he paid £40 for a nail making machine which he set up in an old engineering works in Stalybridge. In 1860 he installed one of the country’s first hand sheet rolling mills to roll puddled iron into crude steel sheets for clog nails. A plate mill also turned out over a hundred tons of iron plates a week for local boiler shops.</p>
          <p>John died in 1876 and the small but thriving business passed into the hands of three of his seven sons, James, John and Alfred. Little further progress in terms of expansion was made until they took into partnership the second youngest son, Henry Hall (Harry) Summers in 1889. The story continues - <a href="/years">View the Timeline</a> </p>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6">
              <img className="img-rounded home-img" src="/images/_47565702_010.jpg" alt="Shotton Steel" width="100%" />
              <img className="img-rounded home-img" src="/images/_47565703_009.jpg" alt="Shotton Steel" width="100%" />
              <img className="img-rounded home-img" src="/images/_47565704_007.jpg" alt="Shotton Steel" width="100%" />
              <img className="img-rounded home-img" src="/images/_47565705_006.jpg" alt="Shotton Steel" width="100%" />
              <img className="img-rounded home-img" src="/images/_47566569_035.jpg" alt="Shotton Steel" width="100%" />
            </div>
            <div className="col-md-6">
              <img className="img-rounded home-img" src="/images/_47566572_037.jpg" alt="Shotton Steel" width="100%" />
              <img className="img-rounded home-img" src="/images/_47566573_038.jpg" alt="Shotton Steel" width="100%" />
              <img className="img-rounded home-img" src="/images/_47566648_008.jpg" alt="Shotton Steel" width="100%" />
              <img className="img-rounded home-img" src="/images/_47568288_1392139557_be697159b9_b-1.jpg" alt="Shotton Steel" width="100%" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <em>
            <p>This history has been compiled by Gordon Smith, Information Services Manager, Shotton, 1968-1997. His principal sources of information were: 1895-1996: Full Circle, the story of steelmaking on Deeside, written by Gordon Smith, published 1980; The Summers of Shotton, written by Brian Redhead and Sheila Gooddie, published 1987; A Century of Shotton Steel, written by Gordon Smith, published 1996; Steel News, the in-house news magazine of British Steel Corporation, 1967-1991;Coated Products Update and Corus Colors news magazines, 1991-2006; Management and Staff Pensioners’ reunion newsletters, 2004-2014. Tata Steel Shotton Business Update July 2013; European Coil Coating Association’s information web site on pre-painted metals, November 2013.</p>
          </em>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
